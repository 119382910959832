<template>
	<div class="contact-body" v-if="show==1" >
		<!-- v-drag -->
		<div class="contact-cancel" @click="show=0">×</div>
		<div class="contact-left">
			<div class="contact-search">
				<div>
					<img src="../../assets/img/home/search.png" /><input placeholder="搜索联系人" v-model="name" @input="getmess('')" />
				</div>
			</div>
			<div v-for="(item,index) in message" :key="index" :class="['contact-left-item', choose==index ? 'contact-left-item contact-left-item-c':'']"
			 @click="choosesay(index,item.school_id)">
				<img :src="item.school_logo" />
				<div>{{item.school_name}}</div>
			</div>
		</div>
		<div class="contact-right">
			<div class="right-body">
				<div class="contact-time">
					{{hour}}:{{min}}
				</div>
				<div class="contact-right-item">
					<div v-for="(item,indexa) in content" :key="indexa">
						<div class="contact-say-time">{{item.addtime}}</div>
						<div class="p-float-right" v-if="item.type==4">
							<div class="contact-left-item-right">
								<div>
									<div class="rightsay">
										<div class="sayall">{{item.content}}</div>
									</div>
								</div>
								<img class="common-img mr20" :src="mylogo" />
							</div>
						</div>
						<div class="contact-left-item-left" v-if="item.type==3">
							<img class="common-img" :src="message[choose].school_logo" />
							<div>
								<div class="leftsay">
									<div class="sayall">{{item.content}}</div>
								</div>
							</div>
						</div>
					</div>
					<div id="end"></div>
				</div>
			</div>
			<div class="right-bottom" id="wangeditor">
				<textarea ref="editorElem" maxlength="100" v-model="editorContent" class="right-bottom">
				</textarea>
				<div class="right-button" @click="setmessage()">发送</div>
			</div>
		</div>
	</div>
</template>

<script>
	import E from "wangeditor";
	export default {
		name: "Editor",
		data() {
			return {
				choose: 0,
				hour: "",
				min: '',
				isflat: 1,
				editor: null,
				shcoolid: '',
				name: "",
				show: 0,
				mylogo: "",
				editorContent: '',
				talker: [],
				content: [],
				message: [{
						name: "机构1",
					},
					{
						name: "机构2",
					}
				]
			};
		},
		props: ['catchData'],
		created() {
			var myDate = new Date();
			this.hour = myDate.getHours();
			this.min = myDate.getMinutes();
			if (localStorage.getItem('token')) {
				//this.getmessege()
			}

		},
		mounted() {

		},
		methods: {
			choosesay(e, a) {
				this.choose = e
				this.shcoolid = a
				this.getlist(a)
				document.getElementById("end").scrollIntoView();
			},
			toshow(sid) {
				if (localStorage.getItem('token')) {
					this.show = 1
					this.getmess(sid)
					this.mylogo = localStorage.getItem("img")
				} else {
					this.$message.error("请登录")
				}
			},
			getmess(sid) {
				this.$get("user/getmsg/", {
					school_id: sid,
					name:this.name
				}).then(response => {
					if (response.error == 0) {
						this.message = response.data.school_list
						this.getlist(sid)
					} else {

						return
					}
				});
			},
			getlist(sid) {
				this.$get("user/getschoolmsginfo", {
					school_id: sid,
				}).then(response => {
					if (response.error == 0) {
						this.content = response.data
					} else {
			
						return
					}
				});
			},
			setmessage() {
				this.$post("user/sendprivatemsg", {
					school_id: this.shcoolid,
					content: this.editorContent
				}).then(response => {
					if (response.error == 0) {
						this.getmess(this.shcoolid)
						this.choosesay(this.choose, this.shcoolid)
						this.editorContent = ''
						document.getElementById("end").scrollIntoView();
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			//之前接口的数据
			// getmessege() {
			// 	this.$get("user/getstationmsg", {
			// 		type: 3,
			// 		school_name: this.name
			// 	}).then(response => {
			// 		if (response.error == 0) {
			// 			if (response.data.msgWindow.length != 0 && response.data.msgWindow) {
			// 				this.message = response.data.msgWindow
			// 				this.shcoolid = response.data.msgWindow[0].type_id
			// 				this.message[0].msg_list = response.data.msgWindow[0].msg_list.reverse()
			// 				this.message[1].msg_list = response.data.msgWindow[1].msg_list.reverse()
			// 			} else {
			// 				this.message = [{
			// 						avatarurl: "",
			// 						max_addtime: 1612249601,
			// 						msg_list: [{
			// 							addtime: "2021-02-02 15:06",
			// 							content: "空",
			// 							id: 16,
			// 							status: 1,
			// 							type: 4,
			// 							type_id: 1,
			// 							user_id: 25,
			// 						}],
			// 						school_logo: "https://thirdwx.qlogo.cn/mmopen/vi_32/glMH9jUOFSibpA7AlGf4Iwc5FlxYSbcMXFP6c7bAxLEvGyvrn0vsKeSLS2FPgSDTeYtdpvPRZ1qOQJhhSwFqnVw/132",
			// 						school_name: "暂没机构",
			// 						type_id: 1,
			// 					},
			// 					{
			// 						avatarurl: "",
			// 						max_addtime: 1612249601,
			// 						msg_list: [{
			// 							addtime: "2021-02-02 15:06",
			// 							content: "空",
			// 							id: 16,
			// 							status: 1,
			// 							type: 4,
			// 							type_id: 1,
			// 							user_id: 25,
			// 						}],
			// 						school_logo: "https://thirdwx.qlogo.cn/mmopen/vi_32/glMH9jUOFSibpA7AlGf4Iwc5FlxYSbcMXFP6c7bAxLEvGyvrn0vsKeSLS2FPgSDTeYtdpvPRZ1qOQJhhSwFqnVw/132",
			// 						school_name: "暂没机构",
			// 						type_id: 1,
			// 					}
			// 				]
			// 			}

			// 		} else {
			// 			this.$message.error(response.msg)
			// 			return
			// 		}
			// 	});
			// },
		},
		directives: {
			drag(el, bindings) {
				console.log(el, "請問下有問題不?")
				el.onmousedown = function(e) {
					var disx = e.pageX - el.offsetLeft;
					var disy = e.pageY - el.offsetTop;
					document.onmousemove = function(e) {
						el.style.left = e.pageX - disx + 'px';
						el.style.top = e.pageY - disx + 'px';
					}
					document.onmouseup = function() {
						document.onmousemove = document.onmouseup = null;
					}
				}
			}
		},
	};
</script>


<style>
	.contact-body {
		width: 600px;
		height: 450px;
		position: fixed;
		top: 150px;
		right: 88px;
		z-index: 300;
		background: #F5F5F5;
		box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.15);
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	.contact-left {
		width: 150px;
		height: 450px;
		background: #ECEAE8;
	}

	.contact-right {
		width: 450px;
		height: 450px;
	}

	.right-body {
		height: 324px;
		overflow-y: scroll;
	}

	.contact-search {
		width: 150px;
		height: 40px;
		background: #F5F4F4;
	}

	.contact-search div {
		position: relative;
		width: 130px;
		height: 20px;
		background: #ECEAE8;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		top: 9px;
	}

	.contact-search div img {
		width: 14px;
		height: 12px;
		margin-top: auto;
		margin-bottom: auto;
		margin-left: 6px;
	}

	.contact-search div input {
		width: 80px;
		height: 20px;
		font-size: 12px;
		background: #ECEAE8;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
		line-height: 31px;
		border: none;
		margin-top: auto;
		margin-bottom: auto;
		margin-left: 7px;
	}

	.contact-left-item {
		width: 150px;
		height: 60px;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	.contact-left-item-c {
		background: #F5F5F5;
	}

	.contact-left-item img {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		margin-top: auto;
		margin-bottom: auto;
		margin-left: 10px;
	}

	.contact-left-item div {
		margin-left: 9px;
		margin-top: 14px;
		font-size: 12px;
		width: 90px;
		overflow: hidden;
		white-space: nowrap;
		/*内容超宽后禁止换行显示*/
		text-overflow: ellipsis;
		/*文字超出部分以菌棍距省略号显示*/
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
	}

	.contact-time {
		width: 40px;
		height: 21px;
		background: #ECEAE8;
		border-radius: 5px;
		text-align: center;
		line-height: 21px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 17px;
		margin-bottom: 8px;
	}

	.contact-right-item {
		width: 100%;
		height: auto;
	}

	.contact-left-item-left {
		display: flex;
		width: 100%;
		margin-bottom: 20px;
		height: auto;
	}

	.common-img {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		margin-left: 20px;
		margin-top: 10px;
	}

	.leftsay {
		height: auto;
		background: #FFFFFF;
		border-radius: 10px;
		margin-left: 10px;
		margin-right: 68px;
		margin-top: 10px;
	}

	.sayall {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 18px;
		padding-top: 15px;
		padding-bottom: 15px;
		margin-left: 20px;
		margin-right: 20px;

	}

	.p-float-right {
		width: 100%;
		height: auto;
	}

	.contact-left-item-right {
		display: flex;
		height: auto;
		justify-content: flex-end;
		margin-top: 34px;
	}

	.rightsay {
		height: auto;
		background: #FFFFFF;
		border-radius: 10px;
		margin-left: 64px;
	}

	.mr20 {
		margin-right: 20px;
		margin-top: 0px !important;
	}

	.right-bottom {
		width: 100%;
		height: 126px;
		outline: none !important;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		border: none;
		resize: none;
		cursor: pointer;
	}

	.right-button {
		position: absolute;
		z-index: 300;
		width: 58px;
		height: 25px;
		margin-top: -32px;
		margin-left: 390px;
		background: #F5F5F5;
		border: 1px solid #E6E6E6;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
		line-height: 25px;
		text-align: center;
	}

	.contact-cancel {
		position: absolute;
		margin-left: 570px;
		font-size: 28px;
		width: 30px;
		color: #333333;
	}

	.contact-say-time {
		width: 100%;
		height: 20px;
		line-height: 20px;
		text-align: center;
		color: #999999;
		font-size: 10px;
		margin-top: 30px;
	}
</style>
