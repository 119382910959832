<template>
	<div class="contenttop" v-show="choose">
		<img class="buycar"   @click="tobuycar" src="../../assets/img/buycar/tobuycar.png" />
		<img class="totop"   @click="top" src="../../assets/img/home/totop.png" />
	</div>
</template>

<script >
	export default {
		data() {
			return {
				choose: false,
			}
		},
		watch: {
			'$route': function() {
				window.addEventListener('scroll', this.handleScroll, true)
			},
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll, true)
		},
		directives: {
			drag(el, bindings) {
				el.onmousedown = function(e) {
					var disx = e.pageX - el.offsetLeft;
					var disy = e.pageY - el.offsetTop;
					document.onmousemove = function(e) {
						el.style.left = e.pageX - disx + 'px';
						el.style.top = e.pageY - disx + 'px';
					}
					document.onmouseup = function() {
						document.onmousemove = document.onmouseup = null;
					}
				}
			}
		},
		methods: {
			handleScroll(){
				var top = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
				if(top>500){
					this.choose = true
				}
				if(top<500){
					this.choose = false
				}
			},
			tobuycar(){
				if(!localStorage.getItem("token")){
					this.$message.error("请先登录")
					return
				}
				this.$router.push(
				{
					path:"/buycar"
				}
				)
			},
			top() {
				scrollTo(0, 0);
			},
		}
	}
</script>

<style>
	.contenttop {
		position: fixed;
		top: 50%;
		width: 64px;
		height: 130px;
		background: #FFFFFF;
		right: 10px;
		border: 1px solid #E6E6E6;
		z-index: 200;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
	}
	.buycar{
		height: 33px;
		height: 37px;
		cursor: pointer;
	}
	.totop {
		height: 31px;
		width: 31px;
		cursor: pointer;
	}
</style>
